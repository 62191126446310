import * as React from 'react';
import { SVGProps } from 'react';
const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.215 11.9a.744.744 0 0 0 0 1.046.762.762 0 0 0 1.054 0l5.312-5.313 5.313 5.313a.754.754 0 0 0 1.054 0 .752.752 0 0 0 0-1.046L7.636 6.58l5.312-5.313a.744.744 0 0 0 0-1.046.746.746 0 0 0-1.054 0L6.581 5.533 1.27.221a.74.74 0 0 0-1.054 0 .752.752 0 0 0 0 1.046l5.312 5.312L.215 11.9Z"
      fill={props.color}
    />
  </svg>
);
export default SvgClose;

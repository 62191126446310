import React from 'react';

import CloseIcon from '@amazd/common/icons/CloseIcon';
import { colors } from '@amazd/common/static';
import { IconButton, Typography, Box } from '@mui/material';

import { ToastProps } from './types';

const StaticToast = React.forwardRef<HTMLDivElement, ToastProps>(
  ({ title, text, severity = 'info', dismissable = true, onClose, sx, containerSx }: ToastProps, ref): JSX.Element => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          background: (() => {
            switch (severity) {
              case 'success':
                return '#E4FFF1';
              case 'error':
                return '#FFE6F0';
              case 'info':
                return colors.amazdGrey6;
            }
          })(),
          boxShadow: '0px 0px 20px rgb(57 63 114 / 20%)',
          height: 'fit-content',
          width: '340px',
          borderRadius: '10px',
          ...sx,
        }}
        ref={ref}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'column',
            padding: '10px',
            borderLeft: (() => {
              switch (severity) {
                case 'success':
                  return '8px solid #56C087';
                case 'error':
                  return `8px solid #FF0066`;
                case 'info':
                  return `8px solid ${colors.amazdGrey4}`;
              }
            })(),
            minHeight: '70px',
            borderBottomLeftRadius: '10px',
            borderTopLeftRadius: '10px',
            ...containerSx,
          }}
        >
          {title && (
            <Typography variant="subtitle1" sx={{ fontWeight: 500, fontSize: '16px', lineHeight: '26px', mb: '2px' }}>
              {title}
            </Typography>
          )}
          <Typography style={{ fontSize: '14px', lineHeight: '20px' }} variant="body2">
            {text}
          </Typography>
        </Box>
        {severity === 'success' && dismissable && (
          <IconButton onClick={onClose}>
            <CloseIcon style={{ fontSize: 18 }} />
          </IconButton>
        )}
      </Box>
    );
  },
);

StaticToast.displayName = 'StaticToast';

export default StaticToast;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgShopIntegration = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M9.5 10.75H14.5a1 1 0 0 1 1 1V13a2.251 2.251 0 0 1-2.25 2.251H10.75a2.251 2.251 0 0 1-2.251-2.25V11.75a1 1 0 0 1 1-1Z"
      stroke="#323232"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.035 10.75V8.499M13.965 10.75V8.499M16.002 20.055A8.998 8.998 0 1 0 2.996 12a9.352 9.352 0 0 0 2.745 6.627v0a6.985 6.985 0 0 0 4.93 2.037v0a1.33 1.33 0 0 0 1.33-1.329v-4.084"
      stroke="#323232"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgShopIntegration;

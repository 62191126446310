import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  root: {
    display: 'flex',
  },
  touchable: {
    cursor: 'pointer',
  },
}));

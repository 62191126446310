import * as React from 'react';
import { SVGProps } from 'react';
const SvgLineAddProduct = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.25 6.5a.75.75 0 0 0 1.5 0h-1.5Zm4.5-4v-.75.75Zm3.5 4a.75.75 0 0 0 1.5 0h-1.5Zm4 2a.75.75 0 0 0 1.5 0h-1.5ZM9.098 21.25a.75.75 0 0 0 0-1.5v1.5Zm9.152-6.319a.75.75 0 1 0-1.5 0h1.5Zm-1.5 4.125a.75.75 0 0 0 1.5 0h-1.5Zm-1.313-2.812a.75.75 0 1 0 0 1.5v-1.5Zm4.126 1.5a.75.75 0 0 0 0-1.5v1.5ZM8.75 6.5v-.25h-1.5v.25h1.5Zm0-.25a3 3 0 0 1 .879-2.121L8.568 3.068A4.5 4.5 0 0 0 7.25 6.25h1.5Zm.879-2.121a3 3 0 0 1 2.121-.879v-1.5a4.5 4.5 0 0 0-3.182 1.318l1.06 1.06Zm2.121-.879h.5v-1.5h-.5v1.5Zm.5 0a3 3 0 0 1 2.121.879l1.061-1.061A4.5 4.5 0 0 0 12.25 1.75v1.5Zm2.121.879a3 3 0 0 1 .879 2.121h1.5a4.5 4.5 0 0 0-1.318-3.182l-1.06 1.06Zm.879 2.121v.25h1.5v-.25h-1.5ZM6 7.25h12v-1.5H6v1.5ZM4.75 18.5v-10h-1.5v10h1.5Zm-1.5 0c0 1.518 1.23 2.75 2.749 2.75v-1.5c-.69 0-1.249-.559-1.249-1.25h-1.5ZM18 7.25c.69 0 1.25.56 1.25 1.25h1.5A2.75 2.75 0 0 0 18 5.75v1.5ZM6 5.75A2.75 2.75 0 0 0 3.25 8.5h1.5c0-.69.56-1.25 1.25-1.25v-1.5Zm3.098 14h-3.1v1.5h3.1v-1.5ZM22.25 17a4.75 4.75 0 0 1-4.75 4.75v1.5A6.25 6.25 0 0 0 23.75 17h-1.5Zm-4.75 4.75A4.75 4.75 0 0 1 12.75 17h-1.5a6.25 6.25 0 0 0 6.25 6.25v-1.5ZM12.75 17a4.75 4.75 0 0 1 4.75-4.75v-1.5A6.25 6.25 0 0 0 11.25 17h1.5Zm4.75-4.75A4.75 4.75 0 0 1 22.25 17h1.5a6.25 6.25 0 0 0-6.25-6.25v1.5Zm-.75 2.681v4.125h1.5v-4.125h-1.5Zm-1.313 2.813h4.126v-1.5h-4.125v1.5Z"
      fill={props.color}
    />
  </svg>
);
export default SvgLineAddProduct;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgMoveToDone = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.707 8.332H7.598c-2.345 0-4.264 1.875-4.264 4.167 0 .922.31 1.776.834 2.468m12.539-6.635-3.334 3.334m3.334-3.334L13.373 5"
      stroke={props.color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgMoveToDone;

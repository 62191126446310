/* eslint-disable */
import React, { SVGProps } from 'react';

import SvgIconArrowLeft from './arrow-left';
import SvgIconClose from './close';
import SvgIconIconBell from './icon-bell';
import SvgIconLineAddProduct from './line-add-product';
import SvgIconMoveToActive from './move-to-active';
import SvgIconMoveToDone from './move-to-done';
import SvgIconPhoneThinFilled from './phone-thin-filled';
import SvgIconShopIntegration from './shop-integration';
import SvgIconSolidWishBagOn from './solid-wish-bag-on';

export type IconType =
  | 'arrow-left'
  | 'close'
  | 'icon-bell'
  | 'line-add-product'
  | 'move-to-active'
  | 'move-to-done'
  | 'phone-thin-filled'
  | 'shop-integration'
  | 'solid-wish-bag-on';

export const iconNames: Array<IconType> = [
  'arrow-left',
  'close',
  'icon-bell',
  'line-add-product',
  'move-to-active',
  'move-to-done',
  'phone-thin-filled',
  'shop-integration',
  'solid-wish-bag-on',
];

export interface IconBaseProps extends SVGProps<SVGSVGElement> {
  name: IconType;
}

export function IconBase({ name, ...rest }: IconBaseProps) {
  switch (name) {
    case 'arrow-left':
      return <SvgIconArrowLeft {...rest} />;
    case 'close':
      return <SvgIconClose {...rest} />;
    case 'icon-bell':
      return <SvgIconIconBell {...rest} />;
    case 'line-add-product':
      return <SvgIconLineAddProduct {...rest} />;
    case 'move-to-active':
      return <SvgIconMoveToActive {...rest} />;
    case 'move-to-done':
      return <SvgIconMoveToDone {...rest} />;
    case 'phone-thin-filled':
      return <SvgIconPhoneThinFilled {...rest} />;
    case 'shop-integration':
      return <SvgIconShopIntegration {...rest} />;
    case 'solid-wish-bag-on':
      return <SvgIconSolidWishBagOn {...rest} />;
  }
}

import { useEffect } from 'react';

import { useAuthInfo } from '@amazd/common/hooks/auth';
import { User } from '@amazd/common/types/user.types';
import { IFrameInteractions } from '@amazd/common/utils';
import { getMyUser } from '@amazd/common/utils/auth';

const useLogin = (isForProtectedPages?: boolean): [token: string | undefined, user: User | undefined] => {
  const { token, ownUser } = useAuthInfo();
  const urlParams = new URLSearchParams(window.location.search);
  const previousUrl = urlParams.get('previousUrl');
  const useWidgetLogin = urlParams.get('useWidgetLogin');

  useEffect(() => {
    const checkIsLoggedIn = async () => {
      if (token) {
        IFrameInteractions.postMessage('login');
      } else {
        IFrameInteractions.postMessage('logout');
      }
      // If there is token and it's not in the protected page then redirect to home page.
      if (token && !isForProtectedPages) {
        window.location.href = previousUrl || '/';
      }

      // If token is not available and if the route is protected then redirect to login page
      if (!token && isForProtectedPages) {
        window.location.replace(
          `/${useWidgetLogin ? 'login-widget' : 'login'}?previousUrl=${encodeURIComponent(
            window.location.href,
          )}&${urlParams.toString()}`,
        );
      }

      // If token is available and user is not loaded, trigger load user
      if (token && isForProtectedPages && !ownUser) {
        getMyUser();
      }
    };
    void checkIsLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return [token, ownUser];
};

export default useLogin;

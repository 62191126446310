import { colors } from '../../static';
import { IconBase, IconBaseProps } from './generated';
import useStyles from './styles';

export interface IconProps extends IconBaseProps {
  touchable?: boolean;
  size?: number;
}

export function Icon({ name, touchable, size = 24, color = colors.black, width, height, ...rest }: IconProps) {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${touchable ? classes.touchable : ''}`.trim()}>
      <IconBase name={name} color={color} width={width || size} height={height || size} {...rest} />
    </div>
  );
}

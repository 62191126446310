import * as React from 'react';
import { SVGProps } from 'react';
const SvgSolidWishBagOn = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#Solid-wish-bag-on_svg__a)">
      <rect x={4} y={6.5} width={16} height={14} rx={2} fill={props.color} stroke={props.color} strokeWidth={1.5} />
      <path d="M16 6.5a4 4 0 0 0-8 0" stroke={props.color} strokeWidth={1.5} />
      <mask
        id="Solid-wish-bag-on_svg__b"
        maskUnits="userSpaceOnUse"
        x={9.4}
        y={11.101}
        width={15}
        height={13}
        fill="#000"
      >
        <path fill="#fff" d="M9.4 11.101h15v13h-15z" />
        <path d="M18.609 21.191a2.42 2.42 0 0 1-3.418 0l-2.896-2.895a3.04 3.04 0 0 1 4.29-4.308l.315.315.306-.307a3.039 3.039 0 1 1 4.308 4.29l-2.905 2.905Z" />
      </mask>
      <path
        d="M18.609 21.191a2.42 2.42 0 0 1-3.418 0l-2.896-2.895a3.04 3.04 0 0 1 4.29-4.308l.315.315.306-.307a3.039 3.039 0 1 1 4.308 4.29l-2.905 2.905Z"
        fill={props.color}
      />
      <path
        d="m18.609 21.191 1.414 1.415-1.414-1.415Zm-1.71.709v2h.001v-2Zm-1.708-.709-1.415 1.415 1.415-1.415Zm-2.896-2.895 1.414-1.415-.003-.003-1.411 1.418Zm4.29-4.308 1.414-1.415-.003-.002-1.411 1.417Zm.315.315-1.414 1.414a2 2 0 0 0 2.828 0L16.9 14.303Zm.306-.307 1.415 1.414.003-.003-1.418-1.411Zm4.308 4.29 1.414 1.414.003-.003-1.417-1.411Zm-4.32 1.49a.42.42 0 0 1-.295.123l.001 4a4.419 4.419 0 0 0 3.123-1.293l-2.828-2.83Zm-.294.123a.419.419 0 0 1-.295-.122l-2.828 2.829a4.418 4.418 0 0 0 3.122 1.293l.001-4Zm-.295-.122-2.896-2.896-2.828 2.829 2.895 2.896 2.829-2.829Zm-2.899-2.899a1.04 1.04 0 0 1-.306-.734l-4 .008a5.04 5.04 0 0 0 1.484 3.56l2.822-2.834Zm-.306-.734c0-.276.108-.54.303-.736l-2.834-2.823A5.04 5.04 0 0 0 9.4 16.152l4-.008Zm.303-.736a1.04 1.04 0 0 1 .735-.306l-.009-4a5.04 5.04 0 0 0-3.56 1.483l2.834 2.823Zm.735-.306c.275 0 .54.108.736.303l2.822-2.834a5.04 5.04 0 0 0-3.567-1.47l.009 4Zm3.876.615.306-.307-2.828-2.828-.306.306 2.828 2.829Zm.31-.31c.096-.097.21-.173.336-.226l-1.538-3.692a5.042 5.042 0 0 0-1.633 1.096l2.835 2.822Zm.336-.226a1.04 1.04 0 0 1 .398-.08l-.008-4a5.041 5.041 0 0 0-1.928.388l1.538 3.692Zm.398-.08c.137 0 .272.026.398.079l1.523-3.7a5.04 5.04 0 0 0-1.93-.379l.01 4Zm.398.079c.126.052.241.128.338.224l2.822-2.834a5.04 5.04 0 0 0-1.637-1.09l-1.523 3.7Zm.338.224c.097.097.173.211.226.337l3.692-1.538a5.04 5.04 0 0 0-1.096-1.633l-2.822 2.834Zm.226.337c.053.126.08.261.08.398l4-.008a5.04 5.04 0 0 0-.388-1.928L20.32 15.74Zm.08.398c0 .136-.026.272-.078.398l3.698 1.523a5.04 5.04 0 0 0 .38-1.93l-4 .009Zm-.078.398a1.038 1.038 0 0 1-.225.338l2.834 2.822a5.04 5.04 0 0 0 1.09-1.637l-3.7-1.523Zm-5.151-1.135.315.315 2.828-2.829-.315-.315-2.828 2.829Zm4.929 1.47-2.905 2.905 2.828 2.829 2.905-2.906-2.828-2.828Z"
        fill="#fff"
        mask="url(#Solid-wish-bag-on_svg__b)"
      />
    </g>
    <defs>
      <clipPath id="Solid-wish-bag-on_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSolidWishBagOn;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgMoveToActive = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4 9.998h10.93c2.815 0 5.118 2.25 5.118 5a4.892 4.892 0 0 1-1 2.962M4 9.998 8 14m-4-4 4-4"
      stroke={props.color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgMoveToActive;
